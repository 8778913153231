/*
 * @Date: 2023-02-27 03:01:31
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-12 16:03:56
 * @FilePath: \F1-M1-QRP-CODE\config\Host.config.js
 */
let Config = {};

export const DomainURL       = 'https://qrpm1stag.fun88.biz';

export const STAGE_CMS_URL   = 'https://cmsapistag.fun88.biz';
export const SL_CMS_URL      = 'https://cmsapisl.fun88.biz';
export const Prod_CMS_URL    = 'https://cache.f38ebn.com';

export const STAGE_HOST_URL_API  = 'https://f1-qrp-stage-awstk.fubnb.com';
export const HOST_URL_API        = 'https://f1-qrp-live-awstk.fubnb.com';
export const SL_HOST_URL_API     = 'https://f1-qrp-sl-awstk.fubnb.com';

export const STAGE_ENPOINT_URL = 'https://qrpstagingfun88.gamealiyun.com';
export const ENPOINT_URL = 'https://qrpfun88.gamealiyun.com';

export const QRCODEURL = 'https://shpfnkpf.funpo.com:2041'
// 判断环境
if ( typeof global.location !== 'undefined' ){
	let LocalHost = global.location.host;
	let isHttps = 'https:' === document.location.protocol;
	let SLAPI = Boolean(
		[
			'qrpm1sl',
			'127.0.0.1:8889',
			'localhost:8889'
		].find((v)=>global.location.href.includes(v))
	)
	
	// 测试环境
	const Devwhich = global.location.host.match(/qrpm1stag\.biz/);
	
	let StagingAPI = Boolean(
		[
			'qrpm1stag'

		].find((v)=>global.location.href.includes(v))
	)
	
	if( StagingAPI ){
		
		
		Config = {
			HostApi   : STAGE_HOST_URL_API,
			LocalHost : '',
			CMSURL    : STAGE_CMS_URL,
			ENDPOINT  : STAGE_ENPOINT_URL,
			QRCODEURL : QRCODEURL
		}
	}else if(SLAPI){
		// 判断是否在master 分支
		
		Config = {
			HostApi   : SL_HOST_URL_API,
			LocalHost : '',
			CMSURL    : SL_CMS_URL,
			ENDPOINT  : ENPOINT_URL,
			QRCODEURL : QRCODEURL
		}
	}else{
		// 判断是否在SL 
		// if ( LocalHost === '' )
		
		Config = {
			/* 灰度地址 */
			HostApi: HOST_URL_API,
			LocalHost: (isHttps ? 'https://' : 'http://') + LocalHost + '/',
			CMSURL: Prod_CMS_URL,
			QRCODEURL : QRCODEURL,

		};
		
	}
		

} 

export default { Config };
